@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Pretendard;
    font-weight: 400;
    src: local("Pretendard Regular"),
    url("assets/fonts/Pretendard-Regular.woff") format('woff'),
    url("assets/fonts/Pretendard-Regular.woff2") format('woff2');
}

@font-face {
    font-family: Pretendard;
    font-weight: 500;
    src: local("Pretendard Medium"),
    url("assets/fonts/Pretendard-Medium.woff") format('woff'),
    url("assets/fonts/Pretendard-Medium.woff2") format('woff2');
}

@font-face {
    font-family: Pretendard;
    font-weight: 700;
    src: local("Pretendard Bold"),
    url("assets/fonts/Pretendard-Bold.woff") format('woff'),
    url("assets/fonts/Pretendard-Bold.woff2") format('woff2');
}

body{
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre{
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
    vertical-align: middle;
}

img,
video {
    max-width: 100%;
    height: auto;
}

::before,
::after {
    border-width: 0;
    border-style: solid;
}

